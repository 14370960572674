import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import it from "./locales/it.json";

const fallbackLng = ["it"];
const lng = navigator.language.substring(0,2);
const availableLanguages = ["en", "it"];
const translationEn = en;
const translationIt = it;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: translationEn },
      it: { translation: translationIt },
    },
  });

export default i18n;
