import { createStore } from "framework7/lite";
import { func } from "prop-types";
import { f7 } from "framework7-react";
import { useTranslation } from "react-i18next";

const store = createStore({
	state: {
		poiShow: null,
		loading: false,
		onFab: false,
		poiData: [],
		poiType: null,
		info: null,
		polling: null,
		center: false,
		report: false,
		poiList: [],
		zoom: 15,
		location: {
			lat: 41.902782,
			lng: 12.496365,
		},
		device: "mobile",
		unityPresence: false,
		filterPathIndexToHide: null,
	},
	getters: {
		loading({ state }) {
			return state.loading;
		},
		onFab({ state }) {
			return state.onFab;
		},
		poiData({ state }) {
			return state.poiData;
		},
		poiType({ state }) {
			return state.poiType;
		},
		info({ state }) {
			return state.info;
		},
		poiShow({ state }) {
			return state.poiShow;
		},
		location({ state }) {
			return state.location;
		},
		center({ state }) {
			return state.center;
		},
		zoom({ state }) {
			return state.zoom;
		},
		report({ state }) {
			return state.report;
		},
		device({state}) {
			return state.device;
		},
		poiList({ state }) {
			return state.poiList;
		},
		unityPresence({ state }) {
			return state.unityPresence;
		},
		filterPathIndexToHide({ state }) {
			return state.filterPathIndexToHide;
		}
	},
	actions: {
		setPoiShow({ state }, poi) {
			state.poiShow = poi;
		},
		setLoading({ state }, loading) {
			state.loading = loading;
			//per evitare il blocco del loading
			if (loading) {
				setTimeout(function () {
					state.loading = false;
				}, 10000);
			}
		},
		setPoiData({ state }, poiData) {
			poiData.forEach((poi) => {
				poi.position = {
					lat: Number(poi.coordinates.split(",")[0]),
					lng: Number(poi.coordinates.split(",")[1]),
				};
			});
			state.poiData = poiData;
		},
		setPoiType({ state }, poiType) {
			state.poiType = poiType;
		},
		setInfo({ state }, info) {
			state.info = info;
		},
		setPolling({ state }, polling) {
			state.polling = polling;
		},
		setCenter({ state }, center) {
			state.center = center;
		},
		setZoom({ state }, zoom) {
			state.zoom = zoom;
		},
		setReport({ state }, report) {
			state.report = report;
		},
		pollingGps({ state }) {
			//gestore per l'inizzializzazione della chiamata xr
			let link = "uniwebview://GetLocation";
			window.location.href = link;
			state.polling = null;
		},
		setLocation({ state }, latlon) {
			if (latlon.includes(",")) {
				latlon = latlon.replace(/,/g, ".");
			}
			state.location = {
				lat: Number(latlon.split(":")[0]),
				lng: Number(latlon.split(":")[1]),
			};
		},
		setDevice({state}, device){
			state.device = device;
		},
		setPoiList({ state }, poiList) {
			poiList.forEach(poi => {
				if (poi.type === "miliare") {
					if (poi.subtype === "conservate") {
						poi.icontype = "miliare_stored";
					}
					if (poi.subtype === "!conservate") {
						poi.icontype = "miliare_not_stored";
					}
					if (poi.subtype === "!traianee") {
						poi.icontype = "miliare_not_trajan";
					}
				}
				if (poi.type === "lastra") {
					if (poi.subtype === "conservate") {
						poi.icontype = "lastra_stored";
					}
					if (poi.subtype === "!conservate") {
						poi.icontype = "lastra_not_stored";
					}
					if (poi.subtype === "!traianee") {
						poi.icontype = "lastra_not_trajan";
					}
				}
			})
			state.poiList = poiList;
		},
		setUnityPresence({ state }, unityPresence) {
			state.unityPresence = unityPresence;
		},
		setFilterPathToIndex({ state }, filterPathIndexToHide) {
			state.filterPathIndexToHide = filterPathIndexToHide;
		}
	},
});
export default store;
