import React, { lazy, Suspense } from "react";
import { Page, f7, useStore } from "framework7-react";

// import MapPage from "./MapPage";
const MapPage = lazy(() => import("./MapPage"));
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const HomePage = React.memo(({ f7router }) => {
  const [t, i18n] = useTranslation();
  const loading = useStore("loading");
  const device = useStore("device");
  const version = import.meta.env.VITE_VERSION;

  const onPageAfterIn = () => {
    // if (device === "mobile") {
    //   let link = "uniwebview://CheckUnityPresence";
    //   window.location.href = link;
    // }
  };
  return (
    <Page name="home" onPageAfterIn={onPageAfterIn}>
      <Suspense fallback={<div>Loading…</div>}>
        <MapPage />
      </Suspense>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 999999999999999999999999999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="version">{version}</div>
    </Page>
  );
});
const MemoizedHomePage = React.memo(HomePage);
export default MemoizedHomePage;
