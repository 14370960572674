import HomePage from "../pages/HomePage.jsx";
import SplashPage from "../pages/SplashPage";

import NotFoundPage from "../pages/404.jsx";

var routes = [
	{
		path: "/",
		component: SplashPage,
	},
	{
		path: "/home/",
		component: HomePage,
	},
	{
		path: "(.*)",
		component: NotFoundPage,
	},
];

export default routes;
