import { f7 } from "framework7-react";

const UnityBusReciver = {
	CheckDeviceID: function (deviceID) {
		f7.store.dispatch("CheckDeviceID", deviceID);
	},
	SetVersion: function (appVersion) {
		f7.store.dispatch("SetVersionApp", appVersion);
	},
	setUnityPresence: function () {
		f7.store.dispatch("setUnityPresence", true);
	}
};
export default UnityBusReciver;

window.UnityBusReciver = UnityBusReciver;
