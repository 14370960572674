import React, { useState, useEffect } from "react";

import { f7, f7ready, App, View } from "framework7-react";

import routes from "../js/routes";
import store from "../js/store";

const NSAApp = React.memo(() => {
  // Framework7 Parameters
  const f7params = {
    name: "Appia Regina viarum", // App name
    theme: "auto", // Automatic theme detection
    // App store
    store: store,
    // App routes
    routes: routes,
  };
  f7ready(() => {
    //esponiamo framwork7
    window.f7 = f7;
    if (import.meta.env.VITE_DEBUG && import.meta.env.VITE_DEBUG == "true") {
      console.log(import.meta.env.VITE_DEBUG);
      let html = document.getElementsByTagName("html")[0];
      html.style.backgroundColor = "green";
    }
  });

  return (
    <App {...f7params}>
      {/* Your main view, should have "view-main" class */}
      <View main className="safe-areas" url="/" />
    </App>
  );
});
export default NSAApp;
