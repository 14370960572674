import React, { useEffect } from "react";
import { Page, useStore, f7 } from "framework7-react";
import MobileDetect from "mobile-detect";
import { useTranslation } from "react-i18next";

function sortPoiList(poiList) {
  return poiList.sort((a, b) => {
    if (romanToInt(a.name) > romanToInt(b.name)) {
      return 1;
    }
    if (romanToInt(a.name) < romanToInt(b.name)) {
      return -1;
    }
    if (romanToInt(a.name) && !romanToInt(b.name)) {
      return -1;
    }
    if (!romanToInt(a.name) && romanToInt(b.name)) {
      return 1;
    }
    if (a.name === "-" && b.name !== "-") {
      return 1;
    }
    if (a.name !== "-" && b.name === "-") {
      return -1;
    }
    if (a.name !== "-" && b.name === "-") {
      return -1;
    }
    return 0;
  });
}
function romanToInt(s) {
  let res = 0;
  const symbols = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  };
  s.split("")
    .reverse()
    .forEach((char) => {
      let val = parseInt(symbols[char]);
      if (res > 4 * val) {
        res -= val;
      } else {
        res += val;
      }
    });
  return res;
}

const TutorialPage = ({ f7router }) => {
  const [t, i18n] = useTranslation();

  const beforeInit = () => {
    let md = new MobileDetect(navigator.userAgent);
    let device;
    if (md.phone()) {
      device = "mobile";
    }
    if (md.tablet()) {
      device = "tablet";
    }
    if (md.phone() === null && md.tablet() === null) {
      device = "desktop";
    }
    f7.store.dispatch("setDevice", device);
    GetJsonPOI();
  };
  const GetJsonPOI = () => {
    fetch(import.meta.env.VITE_DB_URL + "appia/poiv2_"+i18n.resolvedLanguage+".json?t=" + Date.now(), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((poiList) => {
        f7.store.dispatch("setPoiList", sortPoiList(poiList));
      });
  };
  const afterInitHome = () => {
    setTimeout(() => {
      f7router.navigate("/home/", {
        clearPreviousHistory: true,
        transition: "f7-fade",
      });
    }, 3000);
  };

  return (
    <Page
      name="splash"
      className="splash-page"
      onPageBeforeIn={beforeInit}
      onPageAfterIn={afterInitHome}
    ></Page>
  );
};
export default TutorialPage;
